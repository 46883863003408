import React from 'react'
import Layout from '../../../components/layout'
import { Link } from 'gatsby'

const Digestive = () => (
  <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Digestive Health</h1>
      </div>
    </section>
    <section className="section-padding biq-bg-white biq-text-black select-none">
      <div className="container">
        <div className="flex biq-text-md mt-6">
          How your breathing affects your stomach, your digestion, and pretty
          much all your intestinal functions has both a cellular and an
          anatomical component. You need oxygen to digest nutrients at
          microscopic level, and you need to breathe well—horizontally, with
          your diaphragm massaging the organs underneath—in order to have your
          digestive organs work effectively.
        </div>
        <div className="flex biq-text-md mt-6">
          A tremendously important adjust to these recommendations are
          correcting the mechanics of the breath so that a more controlled
          breath becomes natural. A biomechanically sound breath also means the
          exhale is more efficient, which is critical in taking an efficient
          inhale and completely taking a full exhale.{' '}
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            Being able to breathe deeply, meaning with your diaphragm, will mean
            fewer GI problems (such as acid reflux, GERD, constipation, and
            IBS). Why? Well, if you breathe Vertically, your diaphragm doesn’t
            massage theorgans directly underneath it—your stomach, colon, liver,
            and kidneys.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            Switching to Horizontal breathing means that you’re giving your
            internal organs, especially your digestive organs, kneading them
            with every breath. It’s called “peristalsis,” the wave like
            squeezing motion of the intestines that promotes digestion and
            elimination.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            If you’re breathing with your shoulders (vertically),your diaphragm
            is barely doing what it should when it comes to digestion. Apart
            from what you’re eating, the biggest factor affecting your digestion
            of food is how effectively the muscles of your stomach are working.
            And you guessed it—food isn’t getting digested the way it should if
            you’re only running on two cylinders.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            You go to the doctor because you have stomach issues: they will end
            up saying something to the effect of “You have irritable bowel! It
            is a common disorder that causes bloating, gas, diarrhea, and
            constipation, but I can tell you that the main cause is stress.” And
            they might mention that you should learn about diaphragmatic
            breathing. If they do mention breathing, it’s likely because, as you
            now know, the diaphragmatic breathing helps your digestive muscles
            do their job, and also because taking lower-body breaths makes you
            calmer. So step one? Make sure you are breathing diaphragmatically.
          </span>
        </div>
        <div className="flex biq-text-md mt-6">
          <span>
            More on how breathing affects your pH and acidity, tips on how to
            manage acid reflux and the most recent studies on how respiratory
            health and digestive health go hand in hand.
          </span>
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default Digestive
